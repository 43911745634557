@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;800&family=Dancing+Script&family=Niconne&family=Roboto&display=swap");
* {
  overflow-anchor: none;
}
body {
  background-color: rgb(255, 255, 255);
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
  font-weight: 800;
}

h1 {
  font-size: 3rem;
}
span {
  word-break: break-word;
}
.start-header {
  background-image: url(resources/images/dogpaws.svg);
  background-position: -50% 100%;
  background-repeat: no-repeat;
  background-size: 70%;
  background-color: rgba(130, 130, 130, 1);
  position: relative;
}
.start-header-content {
  margin-top: -4em;
}
.start-header-content h1 {
  font-size: 4rem;
  color: rgb(253, 90, 172);
}
.start-header-content p {
  font-size: 2.1rem;
  font-weight: 300;
  font-family: "Nunito", sans-serif;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.first-story h1 {
  font-size: 3rem;
}
.first-story {
  font-size: 1.2rem;
}

.btn-action {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0;
  border: 3px solid #000;
}

.btn-action-rose {
  background-color: rgba(255, 53, 155, 0.2);
  border-radius: 0;
  border: 3px solid #000;
}

.btn-nav {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0;
  padding: 1rem;
  font-weight: bold;
}
.btn-nav.active {
  background-color: rgba(255, 255, 255, 1);
}
.btn-nav:focus {
  background-color: rgba(255, 255, 255, 1);
  outline: 0;
  box-shadow: unset;
}

.more-action {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-action-round {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  width: 60px;
  height: 60px;
  border: 3px solid #000;
  font-size: 25px;
}

.nav-container {
  background-color: rgba(130, 130, 130, 0.4);
}

.post-header {
  display: block;
}
.post-date {
  font-size: 1rem;
}

/* styles for '...' */
.post-text {
  width: 100%;
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 3.6em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}

@media (max-width: 360px) {
  .start-header-content h1 {
    font-size: 3rem;
  }

  footer p{
      word-break: break-word;
  }
}
@media (max-width: 575px) { 
  .nav-container .container{
    width: 450px;
  }
 }
/* @media (min-width: 576px) { ... }

@media (min-width: 768px) { ... } */

@media (min-width: 992px) {
  .post-text {
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: 9.4em;
  }
}

@media (min-width: 1200px) {
  /* styles for '...' */
  .post-text {
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: 11.7em;
  }
}

/* create the ... */
.post-text:before {
  /* points in the end */
  content: "...";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}

/* hide ... if we have text, which is less than or equal to max lines */
.post-text:after {
  /* points in the end */
  content: "";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}

h2 a {
  color: rgb(0, 0, 0);
  text-decoration: underline;
}

h2 a:hover {
  color: rgb(0, 0, 0);
  text-decoration: none;
}
.subnav a {
  color: rgb(253, 90, 172);
  text-decoration: underline;
  font-weight: bold;
}

.subnav a:hover {
  color: rgb(253, 90, 172);
  text-decoration: none;
}

.subnav a.active {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

footer {
  background-color: rgba(90, 90, 90, 1);
  color: rgb(255, 255, 255);
}

footer a {
  color: rgb(255, 255, 255);
  text-decoration: underline;
}

footer a:hover {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
/* fade image in after load */
.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

.katteri {
  background-color: #ecf6ff;
  color: #50606e;
}

.katteri h1,
.katteri h2,
.katteri h3 {
  font-family: "Dancing Script", cursive;
  color: #50606e;
}

.katteri a {
  color: #283037;
}

.katteri h1 {
  font-size: 4rem;
}
.katteri h2 {
  font-size: 2.5rem;
}

.katteri .subnav a {
  color: #2679c1;
  text-decoration: underline;
}

.katteri .subnav a:hover {
  color: #283037;
  text-decoration: none;
}

.katteri .subnav a.active {
  color: #283037;
  text-decoration: none;
}
.katteri .sverak-logo {
  width: 100px;
}

.kennel {
  background-color: #f6e4f6;
  color: #8b575c;
}

.kennel h1,
.kennel h2,
.kennel h3 {
  font-family: "Niconne", cursive;
  color: #8b575c;
  line-height: 1;
}

.kennel h1 {
  font-size: 4.5rem;
}
.kennel h2 {
  font-size: 3rem;
}

.katteri .skk-logo {
  width: 150px;
}
